<template>
  <div class="bookdetail">
    <BookDetailLog />
  </div>
</template>

<script>
// @ is an alias to /src
import BookDetailLog from "@/components/BookDetailLog.vue";

export default {
  name: "BookDetail",
  components: {
    BookDetailLog,
  },
};
</script>
