import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Log from "../views/Log.vue";
import Book from "../views/Book.vue";
import BookDetail from "../views/BookDetail.vue";
import Scanner from "../views/Scanner.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/log",
    name: "Log",
    component: Log,
  },
  {
    path: "/book",
    name: "Book",
    component: Book,
  },
  {
    path: "/book-detail/:id",
    name: "BookDetail",
    component: BookDetail,
  },
  {
    path: "/scan-barcode",
    name: "Scanner",
    component: Scanner,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const noauth_pages = ["Login"];
  var token = localStorage.getItem("authTokenAsecaOp");

  if (noauth_pages.includes(to.name) === false && !token)
    next({ name: "Login" });
  if (noauth_pages.includes(to.name) === true && token)
    next({ name: "Dashboard" });
  else next();
});

export default router;
