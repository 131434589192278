<template>
  <div class="login">
    <AuthLogin />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthLogin from "@/components/AuthLogin.vue";

export default {
  name: "Login",
  components: {
    AuthLogin,
  },
};
</script>
