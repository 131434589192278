import Vue from "vue";
import App from "./App.vue";
import VueResource from "vue-resource";
import VueGeolocation from "vue-browser-geolocation";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import auth from "./plugins/auth";
import general from "./plugins/general";
import vuetify from "./plugins/vuetify";

Vue.use(VueResource);
Vue.use(VueGeolocation);
Vue.use(auth);
Vue.use(general);

Vue.config.productionTip = false;

Vue.http.interceptors.push(function (request, next) {
  if (request.url[0] === "/") {
    var token = Vue.auth.getToken();
    if (token && request.url !== "/auth/login") {
      request.headers.set("Authorization", "Bearer " + token);
    }
    if (process.env.NODE_ENV === "production") {
      request.url = "https://www.aseca.id/api/v1/operator" + request.url;
    } else {
      request.url = "http://localhost:8181/api/v1/operator" + request.url;
    }
  }

  next(function (response) {
    if (response.status !== 200) {
      Vue.auth.destroyToken();
      this.user = {};
      this.$router.push("/");
      if (response.body.message !== undefined) {
        this.$root.vtoast.show({
          message: response.body.message,
          icon: "mdi-alert-circle",
          color: "red darken-4",
        });
      }
    } else {
      if (response.body.token !== undefined) {
        Vue.auth.setToken(response.body.token, Date.now() + 2200000000);
      }
      if (response.body.status === "error") {
        var message = response.body.message;
        if (typeof message === "string" && message !== null) {
          this.$root.vtoast.show({
            message: response.body.message,
            icon: "mdi-alert-circle",
            color: "red darken-4",
          });
        } else {
          var txt = "";
          message.forEach((msg) => {
            Object.entries(msg).forEach(([key, value]) => {
              txt += key + ": " + value + ", ";
            });
            this.$root.vtoast.show({
              message: txt,
              icon: "mdi-alert-circle",
              color: "red darken-4",
            });
          });
        }
      } else {
        if (
          response.body.message !== undefined &&
          response.body.message != "Show data"
        ) {
          this.$root.vtoast.show({
            message: response.body.message,
          });
        }
      }
    }
  });
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
