<template>
  <div class="dashboard">
    <DashboardUser />
  </div>
</template>

<script>
// @ is an alias to /src
import DashboardUser from "@/components/DashboardUser.vue";

export default {
  name: "Dashboard",
  components: {
    DashboardUser,
  },
};
</script>
