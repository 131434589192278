<template>
  <div class="log">
    <LogTotal />
  </div>
</template>

<script>
// @ is an alias to /src
import LogTotal from "@/components/LogTotal.vue";

export default {
  name: "Log",
  components: {
    LogTotal,
  },
};
</script>
