var Generalplugin = {
  setRupiah: function (number = "") {
    var Rupiah = "";
    var NumberConv = number.toString().split("").reverse().join("");
    for (var i = 0; i < NumberConv.length; i++);
    if (i % 3 === 0) Rupiah += NumberConv.substr(i, 3) + ",";
    return Rupiah.split("", Rupiah.length - 1)
      .reverse()
      .join("");
  },
  setUcfirst: function (rcatname = "") {
    var resrcatname = rcatname.replace("-", " ");
    var arr = resrcatname.split(" ");
    var resarr = [];

    for (var x = 0; x < arr.length; x++) {
      resarr.push(arr[x].charAt(0).toUpperCase() + arr[x].slice(1));
    }
    return resarr.join(" ");
  },
};

export default function (Vue) {
  Vue.general = Generalplugin;
  Object.defineProperties(Vue.prototype, {
    $general: {
      get: function () {
        return Vue.general;
      },
    },
  });
}
