<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-row dense>
            <v-col cols="12">
              <v-card color="red darken-4 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Detail akun
                </v-card-title>
                <v-card-subtitle
                  >{{ user.username }} <br />{{ user.nama_sekolah }} <br />{{
                    user.alamat_sekolah
                  }}</v-card-subtitle
                >
              </v-card>
              <v-card color="white elevation-20">
                <v-card-subtitle class="font-weight-bold"
                  >Posisi Koordinat: Lat {{ position.lat }} - Lng
                  {{ position.lng }}<br />Waktu: {{ time }}</v-card-subtitle
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row class="text-center mt-6">
            <v-col cols="12">
              <v-btn
                color="teal darken-3"
                elevation="6"
                width="250px"
                dark
                x-large
                @click="scanBaca()"
              >
                <v-icon dark left> mdi-barcode-scan </v-icon> Scan Buku
                (Baca)</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="green darken-3"
                elevation="6"
                width="250px"
                dark
                x-large
                @click="scanMasuk()"
                ><v-icon dark left> mdi-barcode-scan </v-icon> Scan Buku
                (Masuk)</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="red darken-3"
                elevation="6"
                width="250px"
                x-large
                dark
                @click="scanKeluar()"
                ><v-icon dark left> mdi-barcode-scan </v-icon> Scan Buku
                (Keluar)</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="indigo darken-3"
                elevation="6"
                width="250px"
                dark
                x-large
                to="/book"
                ><v-icon dark left> mdi-book </v-icon> Daftar Buku</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                elevation="6"
                width="250px"
                x-large
                to="/log"
                ><v-icon dark left> mdi-file-document-edit </v-icon> Lihat
                Log</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="red darken-3"
                elevation="6"
                width="250px"
                outlined
                dark
                x-large
                @click="logout()"
                ><v-icon dark left> mdi-logout-variant </v-icon> Logout</v-btn
              >
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "DashboardUser",
  data() {
    return {
      position: {},
      time: "00:00:00",
      loading: false,
      barcodetext: "",
    };
  },
  mounted() {
    this.getCurrentLocation();
    setInterval(this.updateTime, 1000);
  },
  methods: {
    scanBaca() {
      this.$store.state.currentUser.scan_status = "baca";
      this.scanBarcode();
    },
    scanMasuk() {
      this.$store.state.currentUser.scan_status = "masuk";
      this.scanBarcode();
    },
    scanKeluar() {
      this.$store.state.currentUser.scan_status = "keluar";
      this.scanBarcode();
    },
    scanBarcode() {
      this.$router.push("/scan-barcode");
    },
    logout() {
      this.$auth.destroyToken();
      this.$router.push("/");
    },
    getCurrentLocation() {
      this.$getLocation({
        enableHighAccuracy: false, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0, //defaults to 0
      }).then((coordinates) => {
        this.position = coordinates;
      });
    },
    updateTime() {
      let date = new Date();
      this.time =
        this.zeroPrefix(date.getDate()) +
        "-" +
        this.zeroPrefix(date.getMonth() + 1) +
        "-" +
        this.zeroPrefix(date.getFullYear()) +
        " " +
        this.zeroPrefix(date.getHours()) +
        ":" +
        this.zeroPrefix(date.getMinutes()) +
        ":" +
        this.zeroPrefix(date.getSeconds());
    },
    zeroPrefix(number) {
      return number < 10 ? "0" + number : number;
    },
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
};
</script>
