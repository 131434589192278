<template>
  <v-app>
    <!-- toast -->
    <vtoast ref="vtoast" />
    <v-app-bar app color="red darken-1" dark>
      <div class="d-flex align-center">
        <v-img
          alt="aseca"
          class="shrink mr-2"
          contain
          src="https://www.aseca.id/img/logo-aseca.png"
          transition="scale-transition"
          width="150"
        />
      </div>

      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <v-img
          alt="ppm"
          class="shrink mr-2"
          contain
          src="https://www.aseca.id/img/logo-ppm.png"
          transition="scale-transition"
          width="150"
        />
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="deep-orange"
    >
      New version available!
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="refreshApp"> Update </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from "./mixins/update";
import vtoast from "@/components/Vtoast";
export default {
  name: "App",
  components: {
    vtoast,
  },
  data: () => ({
    //
  }),
  mixins: [update],
  mounted() {
    this.$root.vtoast = this.$refs.vtoast;
  },
};
</script>
