<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-row dense>
            <v-col cols="12">
              <v-card color="red darken-4 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Detail akun
                </v-card-title>
                <v-card-subtitle
                  >{{ user.username }} <br />{{ user.nama_sekolah }} <br />{{
                    user.alamat_sekolah
                  }}</v-card-subtitle
                >
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="green darken-3 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Total Log:
                  {{
                    total_data.total_baca +
                    total_data.total_masuk +
                    total_data.total_keluar
                  }}
                </v-card-title>
                <v-card-subtitle
                  >Baca: {{ total_data.total_baca }}, Masuk:
                  {{ total_data.total_masuk }}, Keluar:
                  {{ total_data.total_keluar }}</v-card-subtitle
                >
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="indigo darken-2 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Bulan Ini:
                  {{
                    total_data.total_baca_bln_sekarang +
                    total_data.total_masuk_bln_sekarang +
                    total_data.total_keluar_bln_sekarang
                  }}
                </v-card-title>
                <v-card-subtitle
                  >Baca: {{ total_data.total_baca_bln_sekarang }}, Masuk:
                  {{ total_data.total_masuk_bln_sekarang }}, Keluar:
                  {{ total_data.total_keluar_bln_sekarang }}</v-card-subtitle
                >
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="blue darken-1 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Minggu Ini:
                  {{
                    total_data.total_baca_mgg_sekarang +
                    total_data.total_masuk_mgg_sekarang +
                    total_data.total_keluar_mgg_sekarang
                  }}
                </v-card-title>
                <v-card-subtitle
                  >Baca: {{ total_data.total_baca_mgg_sekarang }}, Masuk:
                  {{ total_data.total_masuk_mgg_sekarang }}, Keluar:
                  {{ total_data.total_keluar_mgg_sekarang }}</v-card-subtitle
                >
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="teal darken-1 elevation-20">
                <v-card-title class="text-h5 white--text font-weight-bold">
                  Hari Ini:
                  {{
                    total_data.total_baca_sekarang +
                    total_data.total_masuk_sekarang +
                    total_data.total_keluar_sekarang
                  }}
                </v-card-title>
                <v-card-subtitle class="white--text"
                  >Baca: {{ total_data.total_baca_sekarang }}, Masuk:
                  {{ total_data.total_masuk_sekarang }}, Keluar:
                  {{ total_data.total_keluar_sekarang }}</v-card-subtitle
                >
                <v-data-table
                  :page="page"
                  :pageCount="last_page"
                  :headers="headers"
                  :items-per-page="5"
                  :items="items"
                  :options.sync="options"
                  :server-items-length="total_items"
                  :footer-props="footer_props"
                  :loading="loading"
                  class="elevation-1"
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="text-center mt-6">
            <v-col cols="12">
              <v-btn
                color="grey darken-4"
                outlined
                elevation="6"
                width="250px"
                dark
                x-large
                to="/dashboard"
                ><v-icon dark left> mdi-arrow-left-circle </v-icon> Back</v-btn
              >
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "LogTotal",
  data() {
    return {
      loading: true,
      last_page: 0,
      page: 1,
      total_data: [],
      total_items: 0,
      footer_props: {
        "items-per-page-options": [5, 10],
        "items-per-page-text": "per page",
      },
      options: {},
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Judul Buku", value: "nama", align: "start", sortable: false },
        { text: "Tema", value: "tema", align: "start", sortable: false },
        { text: "Status", value: "status", align: "start", sortable: false },
        { text: "Jam", value: "jam", align: "start", sortable: false },
      ],
      items: [],
    };
  },
  mounted() {
    this.showData();
  },
  methods: {
    showData() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      var pageNumber = page;
      this.$http
        .get("/summary_count_by_operator")
        .then(function (res) {
          this.loading = false;
          if (res.body.status === "success") {
            this.total_data = res.body.total;
            this.$http
              .get(
                "/trans_list_by_operator_now/" + pageNumber + "/" + itemsPerPage
              )
              .then(function (res) {
                this.loading = false;
                this.items = [];
                this.last_page = 1;
                if (res.body.status === "success") {
                  this.items = res.body.data;
                  this.total_items = res.body.total_items;
                  this.last_page = res.body.last_page;
                }
              })
              .catch(function (e) {
                this.$vtoast.show({
                  message: e,
                  icon: "mdi-alert-circle",
                  color: "red darken-4",
                });
              });
          }
        })
        .catch(function (e) {
          this.$vtoast.show({
            message: e,
            icon: "mdi-alert-circle",
            color: "red darken-4",
          });
        });
    },
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
};
</script>
