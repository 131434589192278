<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-row dense>
            <v-col cols="12">
              <v-card color="red darken-4 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Detail akun
                </v-card-title>
                <v-card-subtitle
                  >{{ user.username }} <br />{{ user.nama_sekolah }} <br />{{
                    user.alamat_sekolah
                  }}</v-card-subtitle
                >
              </v-card>
              <v-card color="white elevation-20">
                <v-card-subtitle class="text-h5 font-weight-bold"
                  >Scan Buku: {{ user.scan_status | uppercase }} <br />Result:
                  {{ barcodetext }}</v-card-subtitle
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row class="text-center mt-6">
            <v-col cols="12">
              <div class="scanner">
                <StreamBarcodeReader
                  @decode="(a, b, c) => onDecode(a, b, c)"
                  @loaded="() => onLoaded()"
                ></StreamBarcodeReader>
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="grey darken-4"
                outlined
                elevation="6"
                width="250px"
                dark
                x-large
                to="/dashboard"
                ><v-icon dark left> mdi-arrow-left-circle </v-icon> Back</v-btn
              >
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  name: "ScannerBook",
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      barcodetext: "",
      showbarcode: false,
      endpoint: {
        baca: "transaction_read",
        masuk: "transaction_in",
        keluar: "transaction_out",
      },
    };
  },
  methods: {
    onDecode(a, b, c) {
      console.log(a, b, c);
      this.barcodetext = a;
      if (this.barcodetext !== "") {
        this.sendData(this.barcodetext);
      }
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.barcodetext === a) {
          this.barcodetext = "";
        }
      }, 5000);
    },
    onLoaded() {
      console.log("load");
    },
    getEp(status) {
      let epPath = "";
      if (status == "masuk") {
        epPath = this.endpoint.masuk;
      } else if (status == "keluar") {
        epPath = this.endpoint.keluar;
      } else {
        epPath = this.endpoint.baca;
      }

      return epPath;
    },
    sendData(barcode) {
      let ep = this.getEp(this.$store.state.currentUser.scan_status);
      this.params = {};
      this.params.barcode = barcode;
      this.$http
        .post("/" + ep, this.params)
        .then(function () {})
        .catch(function (e) {
          this.$vtoast.show({
            message: e,
            icon: "mdi-alert-circle",
            color: "red darken-4",
          });
        });
    },
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  filters: {
    uppercase(v) {
      return v.toUpperCase();
    },
  },
};
</script>
