<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="mx-auto" style="max-width: 500px">
            <v-toolbar color="red darken-1" cards dark flat>
              <v-card-title class="text-h6 font-weight-regular">
                Login Operator
              </v-card-title>
            </v-toolbar>
            <v-form ref="form" v-model="form" class="pa-4 pt-6">
              <v-alert
                text
                dense
                color="red"
                icon="mdi-information"
                border="left"
              >
                Silahkan masukkan username dan password anda.
              </v-alert>
              <v-text-field
                v-model="username"
                :rules="[rules.required, rules.min(5), rules.max(20)]"
                filled
                color="red darken-1"
                style="min-height: 96px"
                label="Username"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :rules="[rules.required, rules.min(8)]"
                filled
                color="red darken-1"
                label="Password"
                style="min-height: 96px"
                type="password"
              ></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text @click="clearForm()"> Clear </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="sendData()"
                :disabled="!form"
                :loading="isLoading"
                class="white--text"
                color="red darken-3"
                depressed
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "AuthLogin",
  data: () => ({
    username: undefined,
    form: false,
    isLoading: false,
    password: undefined,
    params: {},
    rules: {
      min: (len) => (v) =>
        (v || "").length >= len || `Minimal panjang karakter: ${len}`,
      max: (len) => (v) =>
        (v || "").length <= len || `Maksimal panjang karakter: ${len} char`,
      match: (field) => (v) =>
        v === field || `Ulangi password harus sama dengan password `,
      required: (v) => !!v || "Inputan ini harus diisi",
    },
  }),
  methods: {
    clearForm() {
      this.$refs.form.reset();
    },
    sendData() {
      this.$refs.form.validate();
      this.isLoading = true;
      this.form = false;
      this.params = {};
      this.params.username = this.username;
      this.params.password = this.password;
      this.$http
        .post("/auth/login", this.params)
        .then(function (res) {
          this.isLoading = false;
          this.form = true;
          if (res.body.status === "success") {
            this.$store.commit("setCurrentUser", res.body.data);
            this.$router.push("/dashboard");
          }
        })
        .catch(function () {
          this.isLoading = false;
          this.form = true;
        });
    },
  },
};
</script>
