var AuthPlugin = {
  setToken: function (token, expiration) {
    localStorage.setItem("authTokenAsecaOp", token);
    localStorage.setItem("authTokenExpirationAseca", expiration);
  },
  destroyToken: function () {
    localStorage.removeItem("authTokenAsecaOp");
    localStorage.removeItem("authTokenExpirationAseca");
  },

  getToken: function () {
    var token = localStorage.getItem("authTokenAsecaOp");
    var expiration = localStorage.getItem("authTokenExpirationAseca");

    if (!token || !expiration) {
      return null;
    }

    if (Date.now() > parseInt(expiration)) {
      this.destroyToken();
      return null;
    }
    return token;
  },

  loggedIn: function () {
    if (this.getToken()) {
      return true;
    }
    return false;
  },
};

export default function (Vue) {
  Vue.auth = AuthPlugin;
  Object.defineProperties(Vue.prototype, {
    $auth: {
      get: function () {
        return Vue.auth;
      },
    },
  });
}
