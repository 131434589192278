<template>
  <div class="book">
    <BookLog />
  </div>
</template>

<script>
// @ is an alias to /src
import BookLog from "@/components/BookLog.vue";

export default {
  name: "Book",
  components: {
    BookLog,
  },
};
</script>
