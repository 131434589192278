<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-row dense>
            <v-col cols="12">
              <v-card color="red darken-4 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Detail akun
                </v-card-title>
                <v-card-subtitle
                  >{{ user.username }} <br />{{ user.nama_sekolah }} <br />{{
                    user.alamat_sekolah
                  }}</v-card-subtitle
                >
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-toolbar color="red darken-1" cards dark flat>
                  <v-card-title class="text-h6 font-weight-regular ml-n4">
                    Daftar Buku
                  </v-card-title>
                </v-toolbar>
                <v-data-table
                  :page="page"
                  :pageCount="last_page"
                  :headers="headers"
                  :items-per-page="5"
                  :items="items"
                  :options.sync="options"
                  :server-items-length="total_items"
                  :footer-props="footer_props"
                  :loading="loading"
                  class="elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon medium color="red darken-3" @click="showItem(item)">
                      mdi-eye-circle</v-icon
                    >
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="text-center mt-6">
            <v-col cols="12">
              <v-btn
                color="grey darken-4"
                outlined
                elevation="6"
                width="250px"
                dark
                x-large
                to="/dashboard"
                ><v-icon dark left> mdi-arrow-left-circle </v-icon> Back</v-btn
              >
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "BookLog",
  data() {
    return {
      page: 1,
      last_page: 0,
      loading: true,
      total_items: 0,
      footer_props: {
        "items-per-page-options": [5, 10],
        "items-per-page-text": "per page",
      },
      params: {},
      options: {},
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Judul Buku",
          align: "start",
          sortable: false,
          value: "nama",
        },
        { text: "Tema", value: "tema", align: "start", sortable: false },
        {
          text: "Donatur ID",
          value: "donatur_id",
          align: "end",
          sortable: false,
        },
        { text: "Dibaca", value: "dibaca", align: "end", sortable: false },
        { text: "View", value: "actions", align: "end", sortable: false },
      ],
      items: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.showData();
      },
      deep: true,
    },
  },
  methods: {
    showItem(item) {
      this.$router.push({ path: "/book-detail/" + item.id });
    },
    showData() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      var pageNumber = page;
      this.$http
        .get("/book_list_by_operator/" + pageNumber + "/" + itemsPerPage)
        .then(function (res) {
          this.loading = false;
          this.items = [];
          this.last_page = 1;
          if (res.body.status === "success") {
            this.items = res.body.data;
            this.total_items = res.body.total_items;
            this.last_page = res.body.last_page;
          }
        })
        .catch(function (e) {
          this.$vtoast.show({
            message: e,
            icon: "mdi-alert-circle",
            color: "red darken-4",
          });
        });
    },
  },
  filters: {
    rupiah: function (value) {
      var val = value ? value.toString() : "0";
      return val.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
    },
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
};
</script>
